// Generated by Framer (4f5e97b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {FLv3sHmwq: {hover: true}, i2WZQxOXC: {hover: true}};

const cycleOrder = ["i2WZQxOXC", "FLv3sHmwq"];

const serializationHash = "framer-wd7lf"

const variantClassNames = {FLv3sHmwq: "framer-v-1qgkk8s", i2WZQxOXC: "framer-v-1qszrv0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {onDark: "FLv3sHmwq", onLight: "i2WZQxOXC"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "i2WZQxOXC"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "i2WZQxOXC", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-wd7lf", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={"https://www.pinterest.com/whiteuistore/"} openInNewTab><Image {...restProps} as={"a"} background={{alt: "Pinterest", fit: "fit", intrinsicHeight: 64, intrinsicWidth: 64, pixelHeight: 64, pixelWidth: 64, src: "https://framerusercontent.com/images/F3KlT7FFbpI0wsA13f8wi09RU.svg"}} className={`${cx("framer-1qszrv0", className)} framer-4tww53`} data-framer-name={"onLight"} layoutDependency={layoutDependency} layoutId={"i2WZQxOXC"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"FLv3sHmwq-hover": {opacity: 0.8}, "i2WZQxOXC-hover": {opacity: 0.8}}} {...addPropertyOverrides({"FLv3sHmwq-hover": {"data-framer-name": undefined}, "i2WZQxOXC-hover": {"data-framer-name": undefined}, FLv3sHmwq: {"data-framer-name": "onDark", background: {alt: "Pinterest", fit: "fit", intrinsicHeight: 96, intrinsicWidth: 96, pixelHeight: 96, pixelWidth: 96, src: "https://framerusercontent.com/images/QlGq4iuX01yTTiX0ERjI7HKg70.png"}}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-wd7lf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wd7lf .framer-4tww53 { display: block; }", ".framer-wd7lf .framer-1qszrv0 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-wd7lf .framer-1qszrv0 { gap: 0px; } .framer-wd7lf .framer-1qszrv0 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-wd7lf .framer-1qszrv0 > :first-child { margin-left: 0px; } .framer-wd7lf .framer-1qszrv0 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FLv3sHmwq":{"layout":["fixed","fixed"]},"XP6pCU2Qp":{"layout":["fixed","fixed"]},"w83H2lyMR":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerAGqEORnY6: React.ComponentType<Props> = withCSS(Component, css, "framer-wd7lf") as typeof Component;
export default FramerAGqEORnY6;

FramerAGqEORnY6.displayName = "Social Icons / Pinterest";

FramerAGqEORnY6.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerAGqEORnY6, {variant: {options: ["i2WZQxOXC", "FLv3sHmwq"], optionTitles: ["onLight", "onDark"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerAGqEORnY6, [])